import React from "react"
import { graphql, Link } from "gatsby"
import * as PropTypes from "prop-types"

import Hero from "../components/Hero"
import Layout from "../components/Layout"
import ContentBlock from "../components/ContentBlock"
import HelmetWrapper from "../components/HelmetWrapper"
import HeroImage from "../../src/images/header_news.jpeg"

const propTypes = {
  data: PropTypes.object.isRequired,
}
const pageKeywords = "central nervous system disorders, rare diseases"
class PressReleaseTemplate extends React.Component {
  capitalize = s => {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  render() {
    const pressrelease = this.props.data.contentfulPressRelease
    const {
      title,
      body,
      heroImage,
      categories,
      description,
      slug,
    } = pressrelease

    const seoTitle =
      typeof title == "undefined" ? null : this.capitalize(title.toUpperCase())

    return (
      <Layout>
        <div id="newsroom" siteName="" pageTitle={title}>
          <HelmetWrapper
            pageTitle={`${seoTitle} `}
            pageDescription={title}
            pageKeywords={pageKeywords}
            slug={`/newsroom/${slug}`}
          />
          <Hero bg={HeroImage}>
            <h1>Newsroom</h1>
          </Hero>
          <ContentBlock color="white" className="newsroom-content">
            <p>
              <a href="javascript:window.print();">
                <button>Print</button>
              </a>
            </p>
            <h2>{title}</h2>
            <div
              className="press-release-container"
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html,
              }}
            />
            <Link to="/newsroom">
              <button aria-label="RETURN TO NEWSROOM">
                RETURN TO NEWSROOM
              </button>
            </Link>
          </ContentBlock>
        </div>
      </Layout>
    )
  }
}

PressReleaseTemplate.propTypes = propTypes

export default PressReleaseTemplate

export const pageQuery = graphql`
  query pressReleaseQuery($slug: String!) {
    contentfulPressRelease(slug: { eq: $slug }) {
      title
      slug
      createdAt(formatString: "MMMM DD, YYYY")
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        file {
          url
        }
      }
    }
  }
`
